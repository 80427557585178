let rootUrl = document.getElementById("linkRoot").getAttribute("href");

export default {
  appType: "admin",
  baseUrl: rootUrl,
  // appEventPrefix: 'cb:admin:', // this is used by auth service
  //cbSettings is a global variable set on the cshtml files and is dynamic based on asp.net configuration values
  apiBaseUrl: cbSettings.apiBaseUrl,
  cdnBaseUrl: cbSettings.cdnBaseUrl,
  loginUrl: cbSettings.loginUrl,
  appInsights: {
    enabled: cbSettings.appInsights && cbSettings.appInsights.enabled,
    instrumentationKey: cbSettings.appInsights && cbSettings.appInsights.instrumentationKey,
  },
  zendeskHelpCenterUrl: cbSettings.zendeskHelpCenterUrl,
  ideasUrl: cbSettings.ideasUrl,
  env: cbSettings.env,
  showImportTab: cbSettings.showImportTab,
  showReportExportButton: cbSettings.showReportExportButton,
  reportingExportThreshold: cbSettings.reportingExportThreshold,
  featureFlagFeatureToggle: cbSettings.featureFlagFeatureToggle,
  sourceSystemIdFeatureToggle: cbSettings.sourceSystemIdFeatureToggle,
  identityBaseUrl: cbSettings.identityBaseUrl,
  viewLogin: '/login',
  viewAutoLogin: '/autoLogin',
  viewHome: '/home',
  viewActionCenter: '/dashboard',
  viewActionCenterV2: '/actioncenter',
  viewActionCenterTaskOnlyUser: '/actioncentertaskonly',
  viewLocations: '/locations',
  viewLocationGroups: '/locations-locationgroups',
  viewTeamMembers: '/locations-users',
  viewBrands: '/locations-brands',
  viewBrandsAdd: '/locations-brands/add',
  viewBrandsEdit: '/locations-brands/edit',
  viewDatafields: '/locations-datafields/:schemaFieldId?',
  viewDatafieldsAddTypeSelect: '/locations-datafields/:schemaFieldId/add',
  viewDatafieldsAdd: '/locations-datafields/:schemaFieldId/add/:type',
  viewCandidates: '/candidates',
  viewCandidatesView: '/candidates/view/:candidateId',
  viewCandidatesProcessView: '/candidates/:candidateId/process/view/:processId',
  viewCandidatesAdd: '/candidates/add',
  viewProcesses: '/processes',
  viewProcess: '/processes/process',
  viewActivityEdit: '/processes/process/activity/edit',
  viewForms: '/processes-forms',
  viewFormsAdd: '/processes-forms/add',
  viewFormsEdit: '/processes-forms/edit/:type',
  viewFormsView: '/processes-forms/view/:type',
  viewFormsDraftSplashView: '/processes-forms/splash/draft',
  viewTasks: '/processes-tasks',
  viewScreenings:'/settings-screening',
  viewScreening:'/settings-screening/screening',
  viewConnections: '/connections/:connectionType',
  viewConnectionEdit: '/connections/:connectionType/:connectionIdentifier/edit',
  viewConnectionLog: '/connections/:connectionType/:connectionIdentifier/log',
  viewCompanies:'/companies',
  viewCompaniesAdd: '/companies/add',
  viewAccessDenied:'/errors/access-denied',
  viewImports: '/locations-imports',
  viewBulkImports: '/bulk-imports',
  viewBulkImportsSpecs: '/bulk-imports-import-specs',
  viewBulkImportsUpdateSpecs: '/bulk-imports-update-specs',
  viewReports: '/reports/:reportType',
    agGridLicense: "Using_this_AG_Grid_Enterprise_key_( AG-048402 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Click Boarding )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 29 November 2024 )____[v2]_MTczMjgzODQwMDAwMA==9d2797c0cdfe807188b5f39cd5d15375"
}
